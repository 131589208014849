import axios from './axiosInit'

const createPackage = async data =>
{
    // console.log('coming here', data)
    const resp = await axios.post('boat_owner_packages/create', data)
    return resp.data
}

const updatePackage = async data =>
{
    try
    {
        const result = await axios.post('boat_owner_packages/update', data)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const postPackage = async (data, endpoint) =>
{
    try
    {
        const result = await axios.post('boat_owner_packages/' + endpoint, data)
        return result.data
    } catch (e)
    {
        console.log(e)
    }
}

const getList = async (options, searchValue, id) =>
{
    try
    {
        let url = 'boat_owner_packages/list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage;

        if (searchValue)
        {
            url += '&search=' + searchValue
        }

        if (id)
        {
            url += '&boat_owner_id=' + id
        }

        const result = await axios.get(url)
        return result.data

    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getCategoryList = async options =>
{
    try
    {
        const result = await axios.get(`categories/list?page=${options.value.page}&per_page=${options.value.itemsPerPage
            }&status=${1}`)

        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getMarinaList = async (options, userData) =>
{
    try
    {
        let url = `marinas/list?page=${options.value.page}&per_page=${options.value.itemsPerPage
            }&status=${1}`;

        if (userData && userData.id)
        {
            url += `&city_id=${userData.id}`;
        }

        const result = await axios.get(url);

        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getCityList = async options =>
{
    try
    {
        const result = await axios.get(`cities/list?page=${options.value.page}&per_page=${options.value.itemsPerPage
            }&status=${1}`)

        return result.data
    } catch (e)
    {
        console.log(e)

        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getCategoryById = async id =>
{
    try
    {
        const result = await axios.get(`categories/list?id=${id}`)

        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getPackages = async (owner_id) =>
{
    try
    {
        const result = await axios.get(`${'boat_owner_packages/list?per_page=100' + '&status='}${1}` + '&boat_owner_id=' + owner_id);

        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getContactDetail = async () =>
{
    try
    {
        const result = await axios.get('contact_detail/fetch-contact-detail')
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const saveContactDetail = async body =>
{
    try
    {
        const result = await axios.post('contact_detail/update-contact-detail', body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const saveBoatCategory = async body =>
{
    try
    {
        const result = await axios.post('categories/create', body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateBoatCategory = async body =>
{
    try
    {
        const result = await axios.post('categories/update', body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const categoryUpdate = async data =>
{
    try
    {
        const result = await axios.post('categories/update', data)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getsinglePackage = async id =>
{
    try
    {
        const result = await axios.get(`boat_owner_packages/list?id=${id}`)

        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateStatus = async (url, body) =>
{
    try
    {
        const result = await axios.post(url, body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const deleteCategory = async body =>
{
    try
    {
        const result = await axios.post('categories/remove', body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

export default {
    createPackage,
    getList,
    getCategoryList,
    updatePackage,
    getsinglePackage,
    updateStatus,
    getPackages,
    getContactDetail,
    saveContactDetail,
    saveBoatCategory,
    updateBoatCategory,
    categoryUpdate,
    getCategoryById,
    deleteCategory,
    getCityList,
    getMarinaList,

    // refined
    postPackage
}
